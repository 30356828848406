.blurOverlayContainer{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0.32;
    filter: blur(300px);
    transform: rotate(-180deg);
    left: 0;
    top: 30%;
    z-index: -1;
    .blurOverlay1{
        background: #F16D78;
        transform: rotate(-180deg);
        width: 33%;
        height: 300px;
    }
    .blurOverlay2{
        background: rgba(0, 164, 153, 0.7);
        transform: rotate(-180deg);
        height: 300px;
        width: 33%;
    }
}