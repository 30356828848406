
.soonBade{
    span {
        padding: 1px 5px 2px;
        border-radius: 4px;
        font-size: 12px;
    }
}
  /* Styles for the chips in desktop-tablet mode */
  ul.desktopTablet {
    display: flex;
    list-style: none;
  }
  
  ul.desktopTablet li {
    cursor: pointer;
    margin-right: 10px;
  }

  
  
   .chips
   {
    /* Existing styles for chips */
    align-items: center;
    //border-left-style: solid;
    //border-left-width: 1px;
    //border-radius: 4px 4px 0px 0px;
    //border-right-style: solid;
    //border-right-width: 1px;
    //border-top-style: solid;
    //border-top-width: 1px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    padding: 10px;
    position: relative;
    cursor: pointer;
    border-color:#ABADB0;
  }
  
  /* Add additional styles for EN chips */
   .chips.active {
    /* Existing styles for EN chips */
    //background-color: #00a4991a;
    //border-color: #00A499;
    gap: 5px;
    padding: 5px;
    border-bottom: 2px solid #00A499;
  }

  .active .textWrapper {
    /* Existing styles for text-wrapper */
    color: var(--Primary-Green-Base, #00A499);
    font-family: var(--web-body-large-semibold-font-family, Inter);
    font-size: var(--web-body-large-semibold-font-size, 18px);
    font-style: var(--web-body-large-semibold-font-style, normal);
    font-weight: var(--web-body-large-semibold-font-weight,600);
    letter-spacing: var(--web-body-large-semibold-letter-spacing);
    line-height: var(--web-body-large-semibold-line-height,24px);   
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  /* Add additional styles for the text in chips */
   .textWrapper
    {
    /* Existing styles for text-wrapper */
    color: var(--Gray-Gray-2, #ABADB0);
    font-family: var(--web-body-large-semibold-font-family, Inter);
    font-size: var(--web-body-large-semibold-font-size, 16px);
    font-style: var(--web-body-large-semibold-font-style, normal);
    font-weight: var(--web-body-large-semibold-font-weight,400);
    letter-spacing: var(--web-body-large-semibold-letter-spacing);
    line-height: var(--web-body-large-semibold-line-height,20px);
    // margin-bottom: -1px;
    // margin-top: -3px;
    position: relative;
    white-space: nowrap;
    width: fit-content;

    
  }
  
  /* Add additional styles for desktop-tablet */
  .desktopTablet {
    /* Existing styles for desktop-tablet */
    display: inline-flex;
  }
  
  .vuesax-broken {
    position: relative;
    width: 20px;
    height: 20px;
  }
  
  .vuesax-broken .img {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    transform: rotate(180deg);
  }