.accountOverlay {
    position: absolute;
    border-radius: 8px;
    z-index: 0;
    background-color: var(--primary-green-transparent-color);
    backdrop-filter: blur(5px);
}

.accountOverlayContent{
    padding: 20px;
    z-index: 1;
}