$golden-color:#d39f72;
.twidgetContainer {
    margin: auto 6px;
    position: relative;
    //height:434px;
    height:auto;
    background-color: #fff;
    @media (max-width: 767px) {
        margin: auto 0px;
    }

    textarea::placeholder {
        color: #ABADB066 !important;
      }
    .twidget {
        width: 100%;
        display: flex;
        position: relative;
        background-color: #fff;
        @media (max-width: 767px) {
            display: block;
        }
        .twidgetTranslateContainer{
            width: 100%;

            .twidgetSelect{
                --tw-ring-color: transparent;
            }
            .translateTextAreaContainer{
                padding: 0 0px 20px 10px;
                position: relative;
                .charCounter{
                    color: #ABADB0;
                }
                .translateTextArea{
                    width: 100%;
                    min-height: 300px;
                    font-size: 18px;
                    resize: none;
                    --tw-ring-color: transparent;
                    color: rgba(16, 24, 32, 1);
                    font-weight: 400;
                    @media (max-width: 480px) {
                        min-height: 230px;
                        font-size: 15px;
                    }
                }
                .translateTextAreaAR {
                    direction: rtl;
                }
                  
            }
        }
        .twidgetBorder{
            border-right: 1px solid #D1D2D4;
            @media (max-width: 767px) {
                border-right: 0;
                border-bottom: 1px solid #D1D2D4;
            }
        }
        .twidgetBorderAr{
            border-left: 1px solid #D1D2D4;
            @media (max-width: 767px) {
                border-right: 0;
                border-bottom: 1px solid #D1D2D4;
            }
        }
    }
}
.transitionFiveSec {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
.shareContent {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    position: relative;
    .share {
        .shareIco {
            display: block;
            list-style: none;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: 1px solid #f0f0f0;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1);
            padding: 8px 12px 12px 10px;
            background: #fff;
            text-align: center;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            font-size: 18px;
            text-align: center;
            @extend .transitionFiveSec;
            a {
                color: $golden-color;
            }
            &.shareIco {
                z-index: 9;
                opacity: 1;
                background: $golden-color;
                a {
                color: #fff;
                }
            }
            &:hover {
                background: $golden-color;
                a {
                color: #fff;
                }
            }
            }
            &.expand {
            li {
                &:nth-child(2) {
                top: 45px;
                opacity: 1;
                }
                &:nth-child(3) {
                top: 90px;
                opacity: 1;
                }
                &:nth-child(4) {
                top: 135px;
                opacity: 1;
                }
            }
        }
    }
}
.MuiMenu-list li.Mui-selected{
    background-color: #F2FAF7 !important;
}