.HeroBackgroundContainer {
    position: absolute;
    display: flex;
    z-index: -1;
    top: 8%;
    width: 100%;
    height: 74.2%;
    overflow: hidden;
    @media (max-width: 1024px) {
        top: 45%;
        height: 450px;
    }
    @media (max-width: 767px) {
        top: 58%;
        height: 350px;
    }
    .bg1 {
        left: -150px;
        width: 50%;
        height: 400px;
        position: absolute;
        overflow: hidden;
        @media (max-width: 1024px) {
            left: -50px;
            width: 50%;
        }
        @media (max-width: 767px) {
            left: -50px !important;
            width: 80%;
        }
    }
    .bg2 {
        right: -150px;
        width: 50%;
        height: 400px;
        position: absolute;
        overflow: hidden;
        @media (max-width: 1024px) {
            right: -50px;
            width: 50%;
        }
        @media (max-width: 767px) {
            right: -50px;
            width: 80%;
        }
    }
}